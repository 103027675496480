import React, { Component } from 'react'
import { graphql, Link } from 'gatsby'
import SEO from '../components/seo'
import Layout from '../components/layout'
import ServiceHero from '../components/service-hero'
import CTA from '../components/cta-bar'
import * as Markdown from 'react-markdown'
import styled from 'styled-components'
import Card from '../components/card'
import { TestimonialSection } from '../components/testimonial_video'

import SocialReviewsFullwidth from '../components/social-reviews-fullwidth'

import { intersperse } from '../helpers/react'

const MainContentContainer = styled.div``
const SidebarContainer = styled.div``

class ServiceCityTemplate extends Component {
  render() {
    function parseContent(string, altCity) {
      if (string) {
        const newString = string.replace(/{{city}}/g, altCity ? altCity : city)
        const secondReplacement = newString.replace(
          /{{citySlug}}/g,
          (altCity ? altCity : city)
            .replace(/\./g, '')
            .toLowerCase()
            .replace(/\s/g, '-') + '-ca'
        )
        return secondReplacement
      }
    }
    let { pageContext } = this.props
    const city = pageContext.city
    let pageData = this.props.data.contentfulServiceAreaContent
    let citiesList = this.props.data.allContentfulServiceArea.edges
    let content = parseContent(pageData.content.content)
    let tidbits = ''
    tidbits = pageContext.tidbits


    const TopBar = () => {
      //pageData has the data
      const topBar = pageData.topbar || []
      return topBar.map(({ id, title, cssClasses, content }) => (
        <div className="topbarcity">
          <section
            className={cssClasses}
            dangerouslySetInnerHTML={{ __html: content.content }}
          />
        </div>
      ))
    }

    const SidebarColumn = () => {
      const sideBarCards = pageData.sidebar.map((sidebar, index) => {
        if (sidebar.__typename == 'ContentfulSidebar') {
          return <Card key={index} sidebar={sidebar} />
        } else if (sidebar.__typename == 'ContentfulSidebarCollection') {
          const innerCards = sidebar.sidebars.map((sidebar, index) => {
            return <Card key={'inner' + index} sidebar={sidebar} />
          })
          return innerCards
        } else return null
      })

      return sideBarCards
    }

    let zipCodes = pageContext.zipCodes
    const { testimonials: testimonial_video_posts } =
    pageData.testimonialSection || { testimonials: [] }
    return (
      <Layout pageProps={this.props}>
        <SEO
          title={parseContent(pageData.serviceTitle)}
          description={parseContent(pageData.metaDescription)}
          img={pageData.heroImage.gatsbyImageData.images.fallback.src}
        />
        <ServiceHero
          pageData={pageData}
          serviceTitle={parseContent(pageData.serviceTitle)}
          heroImage={pageData.heroImage}
          excerpt={parseContent(pageData.excerpt)}
          pageProps={this.props}
          specialOffer={parseContent(pageData.specialOffer)}
          offerDisclaimer={parseContent(pageData.offerDisclaimer)}
          badges={pageData.headerFeaturedBadges}
        />

        <SocialReviewsFullwidth
          modalLinkTo={`/reviews/all`}
        />
 <TestimonialSection
            testimonial_video_posts={testimonial_video_posts}
          />
        <div className="container brand-padding-y">
          <div className="row">
            <MainContentContainer
              className={
                pageData.sidebar
                  ? 'col-12 col-md-8 is-service-page'
                  : 'col-12 is-service-page'
              }
            >
              <div dangerouslySetInnerHTML={{ __html: content }} />
              {zipCodes ? (
                  <h3 className="servicedzip">
                    Serviced ZIP Codes:{' '}
                    {intersperse(
                      zipCodes.map((zipCode, i) => zipCode),
                      ', '
                    )}
                    {zipCodes.map((zipCode) => (
                      <script
                        type="application/ld+json"
                        dangerouslySetInnerHTML={{
                          __html: `
                            {
                              "@context": "https://www.schema.org",
                              "@type": "PostalAddress",
                              "addressLocality": "${city}",
                              "addressRegion": "CA",
                              "postalCode": "${zipCode}"
                            }
                           `,
                        }}
                      />
                    ))}
                  </h3>
                ) : null}
              <div className="pt-4 pb-5">
                <h3>Areas Served</h3>
                <ul className="flex flex-wrap list-none m-0">
                  {citiesList.map((item, i) => (
                    <li key={i} className="w-1/2 md:w-1/3 flex">
                      <div class="flex-shrink-0 pr-1">
                        <svg
                          class="h-6 w-6 text-green-500"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M5 13l4 4L19 7"
                          ></path>
                        </svg>
                      </div>{' '}
                      <Link
                        className="text-gray-800"
                        title={`${parseContent(
                          pageData.serviceTitle,
                          item.node.location
                        )}`}
                        to={`/cities/${item.node.slug}/${pageData.slug}`}
                      >
                        {item.node.location}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </MainContentContainer>

            {pageData.sidebar ? (
              <SidebarContainer className="col-12 col-md-4">
                <SidebarColumn />
              </SidebarContainer>
            ) : (
              ''
            )}
          </div>
        </div>

        <hr />
        <div className="container mt-4 mb-4">
          <h4>Frequently Asked Questions</h4>
          <section
            className="tidbitssec"
            dangerouslySetInnerHTML={{ __html: tidbits }}
          />
        </div>
        <CTA
          title={
            'Start Your ' +
            parseContent(pageData.serviceTitle) +
            ' Project Today!'
          }
          customTitle={parseContent(pageData.footerCallToActionHeading)}
          customDesc={parseContent(pageData.footerCallToActionDesc)}
          bgimage={pageData.footerCallToActionImg}
          serviceHeroImage={pageData.heroImage}
          btn={'Get Started!'}
          props={this.props}
        />
      </Layout>
    )
  }
}

export default ServiceCityTemplate

export const pageQuery = graphql`
  query serviceCityQuery($slug: String!) {
    contentfulServiceAreaContent(slug: { eq: $slug }) {
      id
      serviceTitle
      seoTitle
      metaDescription
      slug
      excerpt
      specialOffer
      socialReviewsFullWidth {
        id
        slug
      }
      offerDisclaimer
      heroImage {
        gatsbyImageData(width: 1200, placeholder: BLURRED)
      }
      content {
        content
      }
      topbar {
        id
        title
        content {
          id
          content
        }
        cssClasses
      }
      headerFeaturedBadges {
        title
        gatsbyImageData(width: 300)
      }
      footerCallToActionHeading
      footerCallToActionDesc
      footerCallToActionImg {
        gatsbyImageData(width: 1800)
      }
      testimonialSection {
        testimonials {
          id
          title
          projectReference {
            slug
          }
          video {
            id
            title
            file {
              url
              contentType
            }
          }
          thumbnail {
            gatsbyImageData(width: 600, quality: 90, placeholder: NONE)
          }
        }
      }
      sidebar {
        __typename
        ... on ContentfulSidebar {
          id
          title
          content {
            id
            content
          }
          cssClasses
        }
        ... on ContentfulSidebarCollection {
          sidebars {
            id
            title
            content {
              id
              content
            }
            cssClasses
          }
        }
      }
    }
    allContentfulServiceArea(sort: { fields: location }) {
      edges {
        node {
          location
          slug
        }
      }
    }
  }
`
